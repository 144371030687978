
import Vue from 'vue';
import Vuex from 'vuex';
import { mapState } from 'vuex';

export default Vue.extend({
    name: 'user-menu',
    computed: {
        ...mapState('user', ['name', 'loggedIn']),
    },
});
