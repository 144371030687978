
import Vue from 'vue';
import Element from 'element-ui';
import { mapState } from 'vuex';
import { CreateLicense, ReadChallenge, ResetChallenge, ResetLicense } from '@/store';

Vue.use(Element);

export default Vue.extend({
    name: 'challenges',
    data() {
        return {
            inputChallenge: this.$storeTyped.state.challenge.challenge,
            generatedLicense: this.$storeTyped.state.challenge.generatedLicenseKey,
            lastmessageTimeStamp: Math.floor(Date.now() / 1000),
        };
    },
    methods: {
        async readChallenge() {
            try {
                if (this.inputChallenge.length > 0) {
                    await this.$store.dispatch(new ReadChallenge(this.inputChallenge));
                } else {
                    this.$store.dispatch(new ResetChallenge());
                }
            } catch (error) {
                const newTimeStamp = Math.floor(Date.now() / 1000);
                if ( newTimeStamp - this.lastmessageTimeStamp  > 2) {
                    this.lastmessageTimeStamp = newTimeStamp;
                    this.$message({
                        message: 'Cannot read challenge: ' + error,
                        type: 'error',
                        showClose: true,
                        duration: 7000});
                }
            }
        },
        async createLicense() {
            try {
                this.$store.dispatch(new ResetLicense());
                if (this.$storeTyped.state.challenge.isValid) {
                    await this.$store.dispatch(new CreateLicense(this.inputChallenge));
                    this.generatedLicense = this.$storeTyped.state.challenge.generatedLicenseKey;
                }
            } catch (error) {
                const newTimeStamp = Math.floor(Date.now() / 1000);
                if ( newTimeStamp - this.lastmessageTimeStamp  > 2) {
                    this.lastmessageTimeStamp = newTimeStamp;
                    this.$message({
                        message: 'Cannot create license: ' + error,
                        type: 'error',
                        showClose: true,
                        duration: 7000});
                }
            }
        },
        async copyLicenseToClipBoard() {
            try {
                const copyText = document.getElementById('licenseKeyField') as HTMLInputElement;
                if ( copyText !== null) {
                    copyText.select();
                    copyText.setSelectionRange(0, 99999);
                    document.execCommand('copy');
                }
            } catch (error) {
                const newTimeStamp = Math.floor(Date.now() / 1000);
                if ( newTimeStamp - this.lastmessageTimeStamp  > 2) {
                    this.lastmessageTimeStamp = newTimeStamp;
                    this.$message({
                        message: 'Cannot copy license to clip board: ' + error,
                        type: 'error',
                        showClose: true,
                        duration: 7000});
                }
            }
        },
    },
    computed: {
        ...mapState('challenge', ['challenge', 'isValid', 'fields', 'generatedLicenseKey']),
    },
});
