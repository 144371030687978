import { ActionTree, Payload } from 'vuex';
import { RootState } from '@/store';
import { JsonClient } from '@/util/JsonClient';
import { BulkState, FileImportMessage, StationAdjustmentXlsx } from './types';
import {read, utils, WorkSheet} from 'xlsx';
import { ImportLayoutMdbMutation, ImportStationAdjustmentXlsxMutation } from './mutations';
import { toBase64 } from '@/util/utils';

export class ImportStationAdjustmentXlsx implements Payload {
    public readonly type = 'bulk/importStationAdjustmentXlsx';
    public readonly xlsx: any;
    public readonly fileName: string;
    constructor(xlsx: any, fileName: string) {
        this.xlsx = xlsx;
        this.fileName = fileName;
    }
}

export class ImportLayoutMdb implements Payload {
    public readonly type = 'bulk/importLayoutMdb';
    public readonly mdb: any;
    public readonly fileName: string;
    constructor(mdb: any, fileName: string) {
        this.mdb = mdb;
        this.fileName = fileName;
    }
}

export class SendStationAdjustmentData implements Payload {
    public readonly type = 'bulk/sendStationAdjustmentData';
}

export default function(backend: JsonClient): ActionTree<BulkState, RootState> {
    return {
        async importStationAdjustmentXlsx({ commit }, payload: ImportStationAdjustmentXlsx) {
            let rows: string[][] = [];
            const parseResult: FileImportMessage = {msg: '', success: false};

            try {
                const wb = read(payload.xlsx);
                const ws: WorkSheet = wb.Sheets[wb.SheetNames[0]];
                rows = utils.sheet_to_json(ws, {header: 1});
            } catch (e: any) {
                parseResult.msg = e;
                return parseResult;
            }
            const stationAdjustmentJson: StationAdjustmentXlsx[] = [];


            if ( rows.length < 2) {
                parseResult.msg = 'Error in XLSX-file';
                return parseResult;
            }
            const columnIndexForStationID = rows[0].findIndex((elem) => elem === 'Station ID');
            const columnIndexForDepthOffset = rows[0].findIndex((elem) => elem === 'Depth Offset');
            const columnIndexForSidewaysOffset = rows[0].findIndex((elem) => elem === 'Sideways Offset');
            const columnIndexForSidewaysSegmentsIn = rows[0].findIndex((elem) => elem === 'Sideways Segments In');
            const columnIndexForSidewaysSegmentsOut = rows[0].findIndex((elem) => elem === 'Sideways Segments Out');

            if (columnIndexForStationID === -1
                || columnIndexForDepthOffset === -1
                || columnIndexForSidewaysOffset === -1
                || columnIndexForSidewaysSegmentsIn === -1
                || columnIndexForSidewaysSegmentsOut === -1) {
                parseResult.msg = 'Required columns were missing or misspelled.';
                return parseResult;
            }

            rows = rows.slice(1, rows.length);
            rows.forEach((r) => {
                const depthOffset = parseInt(r[columnIndexForDepthOffset], 10);
                const sidewaysOffset = parseInt(r[columnIndexForSidewaysOffset], 10);
                const sidewaysSegmentsIn = parseInt(r[columnIndexForSidewaysSegmentsIn], 10);
                const sidewaysSegmentsOut = parseInt(r[columnIndexForSidewaysSegmentsOut], 10);
                stationAdjustmentJson.push(
                    {
                        StationID: parseInt(r[columnIndexForStationID], 10),
                        DepthOffset: isNaN(depthOffset) ? 0 : depthOffset,
                        SidewaysOffset: isNaN(sidewaysOffset) ? 0 : sidewaysOffset,
                        SidewaysSegmentsIn: isNaN(sidewaysSegmentsIn) ? 2 : sidewaysSegmentsIn,
                        SidewaysSegmentsOut: isNaN(sidewaysSegmentsOut) ? 2 : sidewaysSegmentsOut,
                    },
                );
            });

            commit(new ImportStationAdjustmentXlsxMutation(stationAdjustmentJson, payload.fileName));

            parseResult.msg = 'XLSX imported successfully!';
            parseResult.success = true;
            return parseResult;
        },
        async importLayoutMdb({ commit }, payload: ImportLayoutMdb) {
            const parseResult: FileImportMessage = {msg: '', success: false};
            if (!payload.fileName.toLocaleLowerCase().endsWith('.mdb')) {
                parseResult.msg = 'Not an MDB-file';
                return parseResult;
            }
            parseResult.msg = 'MDB imported successfully!';
            parseResult.success = true;
            commit(new ImportLayoutMdbMutation(payload.mdb, payload.fileName));
            return parseResult;
        },
        async sendStationAdjustmentData({ commit, rootState }, payload: SendStationAdjustmentData) {
            if (rootState.bulk.stationAdjustmentData.stationAdjustmentXlsx &&
                rootState.bulk.stationAdjustmentData.layoutMdb) {
                const response = await backend.getStationAdjustmentData(
                    {
                        layoutMdbBuffer: {
                            buffer: toBase64(rootState.bulk.stationAdjustmentData.layoutMdb),
                            layoutName: rootState.bulk.stationAdjustmentData.layoutMdbName,
                        },
                        stationAdjustmentXslxAsJson: rootState.bulk.stationAdjustmentData.stationAdjustmentXlsx,
                    },
                );
                if (response.status === 200) {
                    commit(new ImportStationAdjustmentXlsxMutation(null, ''));
                    commit(new ImportLayoutMdbMutation(null, ''));
                }
                return response;
            }
        },
    };
}
