
import Vue from 'vue';
import Element from 'element-ui';
import { QueryLayoutRevisions } from '@/store/layout/actions';
import { mapState } from 'vuex';
import { LayoutRevisionInfo, AgvLayout } from '@/store/layout/types';
import { parseTime, parseDate } from '@/util/utils';

// Text to shown when information isn't available.
const NA = 'N/A';

Vue.use(Element);

export default Vue.extend({
    name: 'VersionGuard',
    mounted() {
        if ((this.layoutRevisions as any[]).length === 0) {
            this.queryLayoutRevisions();
        } else {
            this.loadedLayoutRevisions = true;
            this.selectedSystemIndex = 0;
        }
    },
    data() {
        return {
            versionGuardAllowed: this.$storeTyped.state.user.versionGuardAllowed,
            loadingLayoutRevisions: true,
            loadedLayoutRevisions: false,
            selectedSystemIndex: undefined as undefined | number,
        };
    },
    methods: {
        async queryLayoutRevisions() {
            try {
                await this.$store.dispatch(new QueryLayoutRevisions());
                this.loadedLayoutRevisions = true;
            } catch (error) {
                this.$message({
                    message: 'Could not get layout revisions: ' + error,
                    type: 'error',
                    showClose: true,
                    duration: 0});
            }
            this.loadingLayoutRevisions = false;
            if (this.layoutRevisions.length > 0) {
                this.selectedSystemIndex = 0;
            }
        },
        selectSystem(index: number) {
            this.selectedSystemIndex = index;
        },
        // Check if there's a mismatch between selected system's layout revisions.
        // If there are no AGV layouts it's considered a mismatch.
        layoutRevisionMismatch(i: number): boolean {
            const agvInfo: AgvLayout[] = this.layoutRevisions[i]?.agv ?? [];
            const agvLayouts = agvInfo.map((a) => a.layoutRevision);
            const licenseLayout = this.layoutRevisions[i]?.license?.layoutRevision;
            const systemLayout = this.layoutRevisions[i]?.system?.layoutRevision;
            return !(agvLayouts.every((a) => licenseLayout === a) && agvLayouts.length > 0
                    && licenseLayout === systemLayout && licenseLayout !== null &&
                    licenseLayout !== undefined);
        },
        parseTime(s: string | null | undefined) {
            return parseTime(s) ?? NA;
        },
        parseDate(s: string | null | undefined) {
            return parseDate(s) ?? NA;
        },
    },
    computed: {
        ...mapState('layout', ['layoutRevisions']),
        licenseLayoutRevision(): string {
            const i = this.selectedSystemIndex;
            return (i !== undefined ? this.layoutRevisions[i]?.license?.
                    layoutRevision ?? NA : NA);
        },
        licenseCreationDate(): string {
            const i = this.selectedSystemIndex;
            return parseDate(i !== undefined ? this.layoutRevisions[i]?.license?.
                    creationTimeStamp : undefined) ?? NA;
        },
        licenseCreationTime(): string {
            const i = this.selectedSystemIndex;
            return parseTime (i !== undefined ? this.layoutRevisions[i]?.license?.
                    creationTimeStamp : undefined) ?? NA;
        },
        licenseEmail(): string {
            const i = this.selectedSystemIndex;
            return (i !== undefined ? this.layoutRevisions[i]?.license?.
                    email ?? NA : NA);
        },
        systemLayoutRevision(): string {
            const i = this.selectedSystemIndex;
            return (i !== undefined ? this.layoutRevisions[i]?.system?.
                    layoutRevision ?? NA : NA);
        },
        agvs(): AgvLayout[] {
            const i = this.selectedSystemIndex;
            return i !== undefined ? this.layoutRevisions[i]?.agv ?? [] : [];
        },
        licenseTime(): string {
            const i = this.selectedSystemIndex;
            return parseTime(i !== undefined ? this.layoutRevisions[i]?.license?.creationTimeStamp : undefined) ?? NA;
        },
        licenseDate(): string {
            const i = this.selectedSystemIndex;
            return parseDate(i !== undefined ? this.layoutRevisions[i]?.license?.creationTimeStamp : undefined) ?? NA;
        },
    },
});
