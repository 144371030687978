import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store, { RootState } from '@/store/index';
import * as Vuex from 'vuex';
import '@/assets/eas-theme.scss';
import '@/assets/design-system.scss';

Vue.config.productionTip = false;

// This creates the $storeTyped alias that shows the correct types in Visual Studio
declare module 'vue/types/vue' {
    interface Vue {
        $storeTyped: Vuex.Store<RootState>;
    }
}

// This makes the $storeTyped alias work during runtime
Object.defineProperty(Vue.prototype, '$storeTyped', {
    get(this: Vue) {
        return this.$store;
    },
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
