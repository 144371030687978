
import Vue from 'vue';
export default Vue.extend({
    props: {
        accept: {
            required: false,
            type: String,
        },
        onFileSelected: {
            required: false,
            type: Function,
        },
        idTag: {
            required: false,
            type: String,
        },
        translationLabel: {
            required: false,
            type: String,
        },
        labelText: {
            required: false,
            type: String,
        },
        labelIcon: {
            required: false,
            type: String,
        },
        customClasses: {
            required: false,
            type: String,
            default: 'input-type-file-to-el-button el-button el-button--primary text-caps',
        },
    },
    methods: {
        processSelection(target: HTMLInputElement): void {
            let file: File | null = null;
            const reader = new FileReader();
            const fileImport: any = this.$refs.fileImport;
            if (target.files && target.files.length > 0) {
                file = target.files[0];
                reader.onload = (e) => {
                    const buffer = reader.result;
                    if (buffer && file) {
                        this.onFileSelected(buffer, file.name);
                    }
                };
                reader.readAsArrayBuffer(file);
            }

            // This is needed to make importing a file with the same name twice in a row.
            fileImport.value = '';
        },
    },
});
