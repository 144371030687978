
import Vue from 'vue';
import Element from 'element-ui';
import FileInput from '@/components/FileInput.vue';
import { ImportStationAdjustmentXlsx, ImportLayoutMdb, SendStationAdjustmentData } from '@/store/bulk/actions';
import { QueryAllLicenses } from '@/store/licenselist/actions';
import { ElMessageOptions } from 'element-ui/types/message';
import { FileImportMessage } from '@/store/bulk/types';
import { downloadFile, base64ToBlob } from '@/util/utils';
import * as XLSX from 'xlsx';

Vue.use(Element);

export default Vue.extend({
    name: 'BulkAdjustment',
    data() {
        return {
            stationAdjustmentAllowed: this.$storeTyped.state.user.stationAdjustmentAllowed,
            stationAdjustmentSent: false,
            downloadingLicenses: false,
        };
    },
    methods: {
        async importStationAdjustmentXlsx(file: File, fileName: string) {
            const res: FileImportMessage = await this.$store.dispatch(new ImportStationAdjustmentXlsx(file, fileName));
            const toastMessage: ElMessageOptions = {
                message: res.msg,
                type: res.success ? 'success' : 'error',
                showClose: true,
                duration: 3000,
            };
            this.$message(toastMessage);
        },
        async importLayoutMdb(file: File, fileName: string) {
            const res: FileImportMessage = await this.$store.dispatch(new ImportLayoutMdb(file, fileName));
            const toastMessage: ElMessageOptions = {
                message: res.msg,
                type: res.success ? 'success' : 'error',
                showClose: true,
                duration: 3000,
            };
            this.$message(toastMessage);
        },
        async sendStationAdjustmentData() {
            this.stationAdjustmentSent = true;
            const res: Response = await this.$store.dispatch(new SendStationAdjustmentData());
            if (res) {
                const responseClone = res.clone();
                try {
                    const resMsg = await res.json();
                    this.stationAdjustmentSent = false;
                    if (res.status === 200) {
                        const fileType = 'application/zip';
                        const fileName = 'bulk_adjustment.zip';
                        const blob = base64ToBlob(resMsg, fileType);
                        downloadFile(blob, fileType, fileName);
                    }
                    const toastMessage: ElMessageOptions = {
                        message: res.status === 200 ? 'File downloaded' : resMsg,
                        type: res.status === 200 ? 'success' : 'error',
                        showClose: true,
                        duration: res.status === 200 ? 3000 : 0,
                    };
                    this.$message(toastMessage);
                }
                // Response was not in JSON format
                catch(error) {
                    this.stationAdjustmentSent = false;
                    const response = await responseClone.text();
                    this.$message({
                        message: 'Unexpected error when adjusting stations ' + response,
                        type: 'error',
                        showClose: true,
                        duration: 0 });
                }
            }
        },
        async exportStationAdjustmentTemplate() {
            const data = [['Station ID', 'Depth Offset', 'Sideways Offset', 'Sideways Segments In', 'Sideways Segments Out']];
            const columnWidths = [
                {wch: 12},
                {wch: 13},
                {wch: 17},
                {wch: 22},
                {wch: 23},
            ];
            const ws = XLSX.utils.aoa_to_sheet(data);
            ws['!cols'] = columnWidths;
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'StatusMapping');
            XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'base64'});
            XLSX.writeFile(wb, 'template.xlsx');
            const toastMessage: ElMessageOptions = {
                message: 'Template exported',
                type: 'success',
                showClose: true,
                duration: 3000,
            };
            this.$message(toastMessage);
        },
    },
    computed: {
        importedXlsxName(): string {
            return this.$storeTyped.state.bulk.stationAdjustmentData.stationAdjustmentXlsxName;
        },
        importedMdbName(): string {
            return this.$storeTyped.state.bulk.stationAdjustmentData.layoutMdbName;
        },
    },
    components: {
        FileInput,
    },
});
