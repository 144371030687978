export interface ChallengeState {
    challenge: string;
    isValid: boolean;
    fields: ChallengeField[];
    generatedLicenseKey: string;
}

export interface ChallengeField {
    name: string;
    value: string;
}

export function EmptyChallengeState() {
    return {
        challenge: '',
        isValid: false,
        fields: [],
        generatedLicenseKey: '',
    };
}
