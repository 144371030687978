import { ActionTree, Payload } from 'vuex';
import { RootState, UserState } from '@/store';
import { SetLoggedInMutation, SetLoggedOutMutation } from './mutations';
import { JsonClient } from '@/util/JsonClient';

export class QueryUser implements Payload {
    public readonly type = 'user/queryUser';
    public readonly parentException: any | null;
    public constructor(parentException: any | null) {
        this.parentException = parentException;
    }
}

export default function(backend: JsonClient): ActionTree<UserState, RootState> {
    return {
        async queryUser({ commit }, payload: QueryUser) {
            let loggedIn: boolean = false;
            try {
                const json = await backend.getUser();
                if (json.loggedIn) {
                    commit(new SetLoggedInMutation(
                        json.userName, json.licenseGenerationAllowed,
                        json.stationAdjustmentAllowed, json.versionGuardAllowed,
                        json.licenseListAllowed));
                    loggedIn = true;
                } else {
                    commit(new SetLoggedOutMutation());
                }
            } catch (e) {
                console.error(e);
                throw new Error('Could not get login information from backend.');
            }
            if (loggedIn && payload.parentException !== null) {
                throw payload.parentException;
            }
        },
    };
}
