import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState, UserState, EmptyUserState } from '@/store';
import { getters } from './getters';
import actions from './actions';
import { mutations } from './mutations';
import { JsonClient } from '@/util/JsonClient';

export class UserModule implements Module<UserState, RootState> {
    public readonly namespaced: boolean = true;
    public readonly state: UserState;
    public readonly actions: ActionTree<UserState, RootState>;
    public readonly getters: GetterTree<UserState, RootState>;
    public readonly mutations: MutationTree<UserState>;

    constructor(backend: JsonClient) {
        this.actions = actions(backend);
        this.mutations = mutations;
        this.getters = getters;
        this.state =  EmptyUserState();
    }
}
