export interface AgvLayout {
    agvId: string;
    layoutRevision: string;
    layoutModificationTime: string;
}

export interface SystemLayout {
    layoutRevision: string;
}

export interface LicenseInfo {
    layoutRevision: string;
    email: string;
    creationTimeStamp: string;
}

export interface LayoutRevisionInfo {
    name: string;
    system: SystemLayout | null;
    agv: AgvLayout[] | null;
    license: LicenseInfo | null;
}

export interface LayoutRevisionState {
    layoutRevisions: LayoutRevisionInfo[];
}

export function EmptyLayoutRevisionState(): LayoutRevisionState {
    return {
        layoutRevisions: [],
    };
}
