import { MutationTree, Payload } from 'vuex';
import { WorkBook } from 'xlsx';
import { BulkState, StationAdjustmentXlsx } from './types';

export class ImportStationAdjustmentXlsxMutation implements Payload {
    public readonly type = 'importStationAdjustmentXlsx';
    public readonly stationAdjustmentXlsx: StationAdjustmentXlsx[] | null;
    public readonly fileName: string;
    constructor(stationAdjustmentXlsx: StationAdjustmentXlsx[] | null, fileName: string) {
        this.stationAdjustmentXlsx = stationAdjustmentXlsx;
        this.fileName = fileName;
    }
}

export class ImportLayoutMdbMutation implements Payload {
    public readonly type = 'importLayoutMdb';
    public readonly layoutMdb: any;
    public readonly fileName: string;
    constructor(layoutMdb: any, fileName: string) {
        this.layoutMdb = layoutMdb;
        this.fileName = fileName;
    }
}

export const mutations: MutationTree<BulkState> = {
    importStationAdjustmentXlsx(state, mutation: ImportStationAdjustmentXlsxMutation) {
        state.stationAdjustmentData.stationAdjustmentXlsx = mutation.stationAdjustmentXlsx;
        state.stationAdjustmentData.stationAdjustmentXlsxName = mutation.fileName;
    },
    importLayoutMdb(state, mutation: ImportLayoutMdbMutation) {
        state.stationAdjustmentData.layoutMdb = mutation.layoutMdb;
        state.stationAdjustmentData.layoutMdbName = mutation.fileName;
    },
};
