
import Vue from 'vue';
import Element from 'element-ui';
import { QueryUser } from '@/store';
import UserMenu from '@/components/UserMenu.vue';
import { ElMessageOptions } from 'element-ui/types/message';

Vue.use(Element);

export default Vue.extend({
    name: 'main-app',
    mounted() {
        this.queryUser();
    },
    methods: {
        async queryUser() {
            await this.$store.dispatch(new QueryUser(null));
            // If license generation isn't allowed, go to another tab.
            if (this.loggedIn
                && this.stationAdjustmentAllowed
                && !this.licenseGenerationAllowed) {
                this.$router.push('/bulk_adjustment');
            } else if (this.loggedIn
                && !this.licenseGenerationAllowed
                && !this.stationAdjustmentAllowed
                && this.versionGuardAllowed
            ) {
                this.$router.push('/version_guard');
            } else if (this.loggedIn
                && !this.licenseGenerationAllowed
                && !this.stationAdjustmentAllowed
                && !this.versionGuardAllowed
                && this.licenseListAllowed) {
                    this.$router.push('/license_list');
                }
        },
    },
    computed: {
        currentRoute(): string {
            return this.$route?.path ?? '/';
        },
        loggedIn(): boolean {
            return this.$storeTyped.state.user.loggedIn;
        },
        stationAdjustmentAllowed(): boolean {
            return this.$storeTyped.state.user.stationAdjustmentAllowed;
        },
        licenseGenerationAllowed(): boolean {
            return this.$storeTyped.state.user.licenseGenerationAllowed;
        },
        versionGuardAllowed(): boolean {
            return this.$storeTyped.state.user.versionGuardAllowed;
        },
        licenseListAllowed(): boolean {
            return this.$storeTyped.state.user.licenseListAllowed;
        },
    },
    components: { UserMenu },
    watch: {
        loggedIn(loggedInNew: boolean, loggedInOld: boolean) {
            if (loggedInOld && !loggedInNew) {
                const options: ElMessageOptions = {
                    message: 'Your session has expired.',
                    type: 'warning',
                    showClose: true,
                    duration: 5000,
                };
                this.$message(options);
            }
        },
    },
});
