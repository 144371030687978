import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/store';
import { getters } from './getters';
import actions from './actions';
import { mutations } from './mutations';
import { JsonClient } from '@/util/JsonClient';
import { EmptyLayoutRevisionState, LayoutRevisionState } from './types';

export class LayoutModule implements Module<LayoutRevisionState, RootState> {
    public readonly namespaced: boolean = true;
    public readonly state: LayoutRevisionState;
    public readonly actions: ActionTree<LayoutRevisionState, RootState>;
    public readonly getters: GetterTree<LayoutRevisionState, RootState>;
    public readonly mutations: MutationTree<LayoutRevisionState>;

    constructor(backend: JsonClient) {
        this.actions = actions(backend);
        this.mutations = mutations;
        this.getters = getters;
        this.state =  EmptyLayoutRevisionState();
    }
}
