import { MutationTree, Payload } from 'vuex';
import { LayoutRevisionInfo, LayoutRevisionState } from './types';

export class SetLayoutRevisionsMutation implements Payload {
    public readonly type = 'setLayoutRevisions';
    public readonly layoutRevisions: LayoutRevisionInfo[];
    constructor(layoutRevisions: LayoutRevisionInfo[]) {
        this.layoutRevisions = layoutRevisions;
    }
}

export const mutations: MutationTree<LayoutRevisionState> = {
    setLayoutRevisions(state, mutation: SetLayoutRevisionsMutation) {
        state.layoutRevisions = mutation.layoutRevisions;
    },
};
