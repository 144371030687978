
import Vue from 'vue';
import Element from 'element-ui';
import { QueryAllLicenses } from '@/store/licenselist/actions';
import { ElMessageOptions } from 'element-ui/types/message';
import * as XLSX from 'xlsx';

Vue.use(Element);

export default Vue.extend({
    name: 'LicenseList',
    data() {
        return {
            licenseListAllowed: this.$storeTyped.state.user.licenseListAllowed,
            downloadingLicenses: false,
        };
    },
    methods: {
        async getAllLicenses() {
            this.downloadingLicenses = true;
            const res = await this.$store.dispatch(new QueryAllLicenses());
            const ws = XLSX.utils.aoa_to_sheet([res.headers].concat(res.data));
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'StatusMapping');
            XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'base64'});
            XLSX.writeFile(wb, 'licenses.xlsx');
            const toastMessage: ElMessageOptions = {
                message: 'All licenses exported',
                type: 'success',
                showClose: true,
                duration: 3000,
            };
            this.downloadingLicenses = false;
            this.$message(toastMessage);
        }
    },
    computed: {
    },
    components: {
    },
});
