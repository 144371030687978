import Vue from 'vue';
import Router from 'vue-router';
import Licenses from './views/Licenses.vue';
import BulkAdjustment from './views/BulkAdjustment.vue';
import VersionGuard from './views/VersionGuard.vue';
import LicenseList from './views/LicenseList.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Licenses',
            component: Licenses,
        },
        {
            path: '/bulk_adjustment',
            name: 'BulkAdjustment',
            component: BulkAdjustment,
        },
        {
            path: '/version_guard',
            name: 'VersionGuard',
            component: VersionGuard,
        },
        {
            path: '/license_list',
            name: 'LicenseList',
            component: LicenseList,
        },
    ],
});
