import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { UserModule } from './user/index';
import { BulkModule } from './bulk/index';
import { ChallengeModule } from './challenge/index';
import { LayoutModule } from './layout/index';
import { JsonClient } from '@/util/JsonClient';
import { ListModule } from './licenselist';

export * from './actions';
export * from './types';

Vue.use(Vuex);

const backend = new JsonClient();

const store: StoreOptions<RootState> = {
    modules: {
        user: new UserModule(backend),
        challenge: new ChallengeModule(backend),
        bulk: new BulkModule(backend),
        layout: new LayoutModule(backend),
        list: new ListModule(backend),
    },
};

export default new Vuex.Store<RootState>(store);
