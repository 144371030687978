export interface StationAdjustmentData {
    stationAdjustmentXlsx: StationAdjustmentXlsx[] | null;
    stationAdjustmentXlsxName: string;
    layoutMdb: ArrayBuffer | null;
    layoutMdbName: string;
}

export interface StationAdjustmentXlsx {
    StationID: number;
    DepthOffset: number;
    SidewaysOffset: number;
    SidewaysSegmentsIn: number;
    SidewaysSegmentsOut: number;
}

export interface FileImportMessage {
    msg: string;
    success: boolean;
}

export interface BulkState {
    stationAdjustmentData: StationAdjustmentData;
}

export function EmptyBulkState(): BulkState {
    return {
        stationAdjustmentData: {
            layoutMdb: null,
            layoutMdbName: '',
            stationAdjustmentXlsx: null,
            stationAdjustmentXlsxName: '',
        },
    };
}
