import { MutationTree, Payload } from 'vuex';
import { ChallengeState, ChallengeField } from '@/store';

export class SetChallengeMutation implements Payload {
    public readonly type = 'challenge/setChallenge';
    public readonly fields: ChallengeField[];
    public readonly challenge: string;
    public readonly isValid: boolean;
    constructor(fields: ChallengeField[], challenge: string, isValid: boolean) {
        this.fields = fields;
        this.challenge = challenge;
        this.isValid = isValid;
    }
}

export class SetLicenseMutation implements Payload {
    public readonly type = 'challenge/setLicense';
    public readonly license: string;
    constructor(license: string) {
        this.license = license;
    }
}

export class ResetChallengeMutation implements Payload {
    public readonly type = 'challenge/resetChallenge';
}

export class ResetLicenseMutation implements Payload {
    public readonly type = 'challenge/resetLicense';
}

export const mutations: MutationTree<ChallengeState> = {
    setChallenge(state, mutation: SetChallengeMutation) {
        state.fields = mutation.fields;
        state.challenge = mutation.challenge;
        state.isValid = mutation.isValid;
        state.generatedLicenseKey = '';
    },
    setLicense(state, mutation: SetLicenseMutation) {
        state.generatedLicenseKey = mutation.license;
    },
    resetChallenge(state, mutation: ResetChallengeMutation) {
        state.fields = [];
        state.challenge = '';
        state.isValid = false;
    },
    resetLicense(state, mutation: ResetLicenseMutation) {
        state.generatedLicenseKey = '';
    },
};
