import { MutationTree, MutationPayload, Payload } from 'vuex';
import { UserState } from '@/store';

export class SetLoggedInMutation implements Payload {
    public readonly type = 'setLoggedIn';
    public readonly name: string;
    public readonly licenseGenerationAllowed: boolean;
    public readonly stationAdjustmentAllowed: boolean;
    public readonly versionGuardAllowed: boolean;
    public readonly licenseListAllowed: boolean;

    constructor(name: string, licenseGenerationAllowed: boolean, stationAdjustmentAllowed: boolean,
                versionGuardAllowed: boolean, licenseListAllowed: boolean) {
        this.name = name;
        this.licenseGenerationAllowed = licenseGenerationAllowed;
        this.stationAdjustmentAllowed = stationAdjustmentAllowed;
        this.versionGuardAllowed = versionGuardAllowed;
        this.licenseListAllowed = licenseListAllowed;
    }
}

export class SetLoggedOutMutation implements Payload {
    public readonly type = 'setLoggedOut';
}

export const mutations: MutationTree<UserState> = {
    setLoggedIn(state, mutation: SetLoggedInMutation) {
        state.name = mutation.name;
        state.licenseGenerationAllowed = mutation.licenseGenerationAllowed;
        state.stationAdjustmentAllowed = mutation.stationAdjustmentAllowed;
        state.versionGuardAllowed = mutation.versionGuardAllowed;
        state.licenseListAllowed = mutation.licenseListAllowed;
        state.loggedIn = true;
    },
    setLoggedOut(state, mutation: SetLoggedOutMutation) {
        state.name = '-';
        state.loggedIn = false;
        state.stationAdjustmentAllowed = false;
        state.licenseGenerationAllowed = false;
        state.versionGuardAllowed = false;
        state.licenseGenerationAllowed = false;
    },
};
