import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/store';
import { getters } from './getters';
import actions from './actions';
import { mutations } from './mutations';
import { JsonClient } from '@/util/JsonClient';
import { LicenseListState, EmptyLicenseListState } from './types';

export class ListModule implements Module<LicenseListState, RootState> {
    public readonly namespaced: boolean = true;
    public readonly state: LicenseListState;
    public readonly actions: ActionTree<LicenseListState, RootState>;
    public readonly getters: GetterTree<LicenseListState, RootState>;
    public readonly mutations: MutationTree<LicenseListState>;

    constructor(backend: JsonClient) {
        this.actions = actions(backend);
        this.mutations = mutations;
        this.getters = getters;
        this.state =  EmptyLicenseListState();
    }
}
