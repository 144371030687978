import { ActionTree, Payload } from 'vuex';
import { RootState } from '@/store';
import { JsonClient } from '@/util/JsonClient';
import { LicenseListState } from './types';
import { QueryUser } from '@/store';

export class QueryAllLicenses implements Payload {
    public readonly type = 'list/queryAllLicenses';
}

export default function(backend: JsonClient): ActionTree<LicenseListState, RootState> {
    return {
        async queryAllLicenses({ commit }, payload: QueryAllLicenses): Promise<any> {
            let json = undefined;
            try {
                json = await backend.getAllLicenses();
            } catch (e) {
                // If the fetch fails, the session has likely timed out,
                // thus need to ask again if the user is logged in.
                try {
                    await this.dispatch(new QueryUser(
                        new Error('' + e)));
                } catch (ie) {
                    console.error(ie);
                    throw ie;
                }
            }
            return json;
        },
    };
}
