export interface UserState {
    name: string;
    loggedIn: boolean;
    licenseGenerationAllowed: boolean;
    stationAdjustmentAllowed: boolean;
    versionGuardAllowed: boolean;
    licenseListAllowed: boolean;
}

export function EmptyUserState() {
    return {
        name: 'none',
        loggedIn: false,
        licenseGenerationAllowed: false,
        stationAdjustmentAllowed: false,
        versionGuardAllowed: false,
        licenseListAllowed: false,
    };
}

