// Download a string as a file
export const downloadFile = (
    content: string | Blob,
    fileType: string,
    fileName: string,
  ) => {
    const blob =
      content instanceof Blob ? content : new Blob([content], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(() => {
      URL.revokeObjectURL(a.href);
    }, 1500);
  };

// Convert a string to base64
export const toBase64 = (buffer: ArrayBuffer | undefined) => {
    if (!buffer) {
        return '';
    }
    const bytes = [].slice.call(new Uint8Array(buffer));
    const binary = bytes.reduce(
        (acc, cur) => (acc += String.fromCharCode(cur)), '');
    return window.btoa(binary);
};

// Convert base64 string to a Blob
export const base64ToBlob = (contents: string, fileType: string) => {
    const byteCharacters = atob(contents);
    const byteArrays = [];
    const sliceSize = 512;
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: fileType});
    return blob;
};

// Return time part from format: 2020-08-20T08:55:45
export const parseTime = (s: string | undefined | null): string | undefined => {
  if (s === undefined || s === null) {
    return undefined;
  }
  const splits = s.split('T');
  if (splits.length > 1) {
    return splits[1];
  }
  return undefined;
};

// Return date part from format: 2020-08-20T08:55:45
export const parseDate = (s: string | undefined | null): string | undefined => {
  if (s === undefined || s === null || s === '') {
    return undefined;
  }
  const splits = s.split('T');
  return splits[0];
};
