import { ActionTree, Payload } from 'vuex';
import { RootState, ChallengeState, ChallengeField, QueryUser } from '@/store';
import { JsonClient } from '@/util/JsonClient';
import { SetChallengeMutation, ResetChallengeMutation, SetLicenseMutation, ResetLicenseMutation } from './mutations';

export class ReadChallenge implements Payload {
    public readonly type = 'challenge/readChallenge';
    public readonly challenge: string;
    constructor(challenge: string) {
        this.challenge = challenge;
    }
}

export class CreateLicense implements Payload {
    public readonly type = 'challenge/createLicense';
    public readonly challenge: string;
    constructor(challenge: string) {
        this.challenge = challenge;
    }
}

export class ResetChallenge implements Payload {
    public readonly type = 'challenge/resetChallenge';
}

export class ResetLicense implements Payload {
    public readonly type = 'challenge/resetLicense';
}

export default function(backend: JsonClient): ActionTree<ChallengeState, RootState> {
    return {
        async readChallenge({ commit }, payload: ReadChallenge) {
            this.commit(new ResetChallengeMutation());
            this.commit(new ResetLicenseMutation());
            try {
                const json = await backend.readChallenge(payload.challenge);
                const fields: ChallengeField[] = [];
                if (json.isValid && json.fields !== null) {
                    json.fields.forEach((x: any) => fields.push({ name: x.name, value: x.value }));
                    this.commit(new SetChallengeMutation(fields, payload.challenge, json.isValid));
                } else {
                    this.commit(new SetChallengeMutation([], payload.challenge, false));
                }
            } catch (e) {
                console.error(e);
                // If the fetch fails, the session has likely timed out,
                // thus need to ask again if the user is logged in.
                try {
                    await this.dispatch(new QueryUser(
                        new Error('Returning packet could not be used, connection to backend might be broken.')));
                } catch (ie) {
                    console.error(ie);
                    throw ie;
                }
            }
        },
        async createLicense({ commit }, payload: CreateLicense) {
            try {
                const json = await backend.generateLicense(payload.challenge);
                if (json.isValid && json.license !== null) {
                    this.commit(new SetLicenseMutation(json.license));
                }
            } catch (e) {
                console.error(e);
                // If the fetch fails, the session has likely timed out,
                // thus need to ask again if the user is logged in.
                try {
                    await this.dispatch(new QueryUser(
                        new Error('Returning packet could not be used, connection to backend might be broken.')));
                } catch (ie) {
                    console.error(ie);
                    throw ie;
                }
            }
        },
        async resetChallenge({ commit }, payload: ResetChallenge) {
            try {
                this.commit(new ResetChallengeMutation());
                this.commit(new ResetLicenseMutation());
            } catch (ie) {
                console.error(ie);
                throw new Error('Could not reset challenge.');
            }
        },
        async resetLicense({ commit }, payload: ResetLicense) {
            try {
                this.commit(new ResetLicenseMutation());
            } catch (ie) {
                console.error(ie);
                throw new Error('Could not reset license.');
            }
        },
    };
}
