import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState, ChallengeState, EmptyChallengeState } from '@/store';
import { getters } from './getters';
import actions from './actions';
import { mutations } from './mutations';
import { JsonClient } from '@/util/JsonClient';

export class ChallengeModule implements Module<ChallengeState, RootState> {
    public readonly namespaced: boolean = true;
    public readonly state: ChallengeState;
    public readonly actions: ActionTree<ChallengeState, RootState>;
    public readonly getters: GetterTree<ChallengeState, RootState>;
    public readonly mutations: MutationTree<ChallengeState>;

    constructor(backend: JsonClient) {
        this.actions = actions(backend);
        this.mutations = mutations;
        this.getters = getters;
        this.state =  EmptyChallengeState();
    }
}


