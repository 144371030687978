import 'portable-fetch';

export class JsonClient {
    private static readonly headers: Record<string, string> = Object.freeze({
        'Content-Type': 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
    });

    public async readChallenge(challenge: string) {
        const response = await fetch('/License/ReadChallenge', {
            method: 'POST',
            headers: JsonClient.headers,
            body: JSON.stringify(challenge),
        });
        return response.json();
    }

    public async generateLicense(challenge: string) {
        const response = await fetch('/License/GenerateLicense', {
            method: 'POST',
            headers: JsonClient.headers,
            body: JSON.stringify(challenge),
        });
        return response.json();
    }

    public async getUser(): Promise<any> {
        const response = await fetch('/Session/UserInfo', {
            method: 'GET',
            headers: JsonClient.headers,
        });
        return response.json();
    }

    public async getStationAdjustmentData(data: any): Promise<Response> {
        const response = await fetch('/Bulk/ReadStationAdjustment', {
            method: 'POST',
            headers: JsonClient.headers,
            body: JSON.stringify(data),
        });
        return response;
    }

    public async getLayoutRevisions(): Promise<any> {
        const response = await fetch('/VersionGuard/LayoutRevisions', {
            method: 'GET',
            headers: JsonClient.headers,
        });
        return response.json();
    }

    public async getAllLicenses(): Promise<any> {
        const response = await fetch('/LicenseList/AllLicenses', {
            method: 'GET',
            headers: JsonClient.headers,
        });
        return response.json();
    }
}
