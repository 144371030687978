import { ActionTree, Payload } from 'vuex';
import { RootState } from '@/store';
import { JsonClient } from '@/util/JsonClient';
import { LayoutRevisionState } from './types';
import { SetLayoutRevisionsMutation } from './mutations';
import { QueryUser } from '@/store';

export class QueryLayoutRevisions implements Payload {
    public readonly type = 'layout/queryLayoutRevisions';
}

export default function(backend: JsonClient): ActionTree<LayoutRevisionState, RootState> {
    return {
        async queryLayoutRevisions({ commit }, payload: QueryLayoutRevisions): Promise<void> {
            try {
                const json = await backend.getLayoutRevisions();
                commit(new SetLayoutRevisionsMutation(json));
            } catch (e) {
                // If the fetch fails, the session has likely timed out,
                // thus need to ask again if the user is logged in.
                try {
                    await this.dispatch(new QueryUser(
                        new Error('' + e)));
                } catch (ie) {
                    console.error(ie);
                    throw ie;
                }
            }
        },
    };
}
